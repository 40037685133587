import { defineMessages } from 'react-intl';

export default defineMessages({
  trialExpiredNotice: {
    id: 'app.containers.NonAuthenticatedAppLayout.trialExpiredNotice',
    defaultMessage:
      'Your free trial of Workpath has expired. To continue using Workpath, please contact {email} to upgrade to one of our plans.',
  },
  subscriptionExpiredNotice: {
    id: 'app.containers.NonAuthenticatedAppLayout.subscriptionExpiredNotice',
    defaultMessage:
      'Your subscription of Workpath has expired. To continue using Workpath, please contact {email} to extend your subscription.',
  },
  help: {
    id: 'app.containers.NonAuthenticatedAppLayout.help',
    defaultMessage: 'Help',
  },
  privacy: {
    id: 'app.containers.NonAuthenticatedAppLayout.privacy',
    defaultMessage: 'Privacy Policy',
  },
  secondaryContentTitle: {
    id: 'app.containers.NonAuthenticatedAppLayout.secondaryContentTitle',
    defaultMessage: 'New Feature: Notes for Goals, Initiatives, and KPIs',
  },
  secondaryContentDescription: {
    id: 'app.containers.NonAuthenticatedAppLayout.secondaryContentDescription',
    defaultMessage:
      'Use Workpath Notes to capture achievements, learnings, risks, challenges, decisions, and more for your Goals, Initiatives, and KPIs. Access them in the Goal Room and review them together in Conversations. For sensitive topics, you can set Notes to "private" to limit visibility to specific people.',
  },
  secondaryContentCTA: {
    id: 'app.containers.NonAuthenticatedAppLayout.secondaryContentCTA',
    defaultMessage: 'Learn more about Workpath Notes',
  },
});
