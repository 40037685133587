import { getPrivacyPolicyUrl } from 'utils/privacyPolicy';
import { Locale } from 'types/Locale';
import { PublicAccount } from 'types/Account';

export const getPrivacyPolicyLink = (
  currentAccount: PublicAccount,
  locale: Locale
) => {
  if (locale === 'en' && currentAccount?.customPrivacyLinkEn) {
    return currentAccount.customPrivacyLinkEn;
  }

  if (locale === 'de' && currentAccount?.customPrivacyLinkDe) {
    return currentAccount.customPrivacyLinkDe;
  }

  return getPrivacyPolicyUrl(locale);
};

export const getCustomHelpText = (
  currentAccount: PublicAccount,
  locale: Locale
) => {
  if (locale === 'en' && currentAccount?.customHelpTextEn) {
    return currentAccount.customHelpTextEn;
  }

  if (locale === 'de' && currentAccount?.customHelpTextDe) {
    return currentAccount.customHelpTextDe;
  }

  return null;
};
