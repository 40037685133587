import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Box, Text } from 'baukasten';
import { TooLazyToType } from 'types/TooLazyToType';
import { getSubdomain } from 'utils/browser';
import { errorMessage } from 'utils/form';
import { NonAuthenticatedAppLayout } from 'containers/NonAuthenticatedAppLayout';
import Alert from 'components/Alert';
import Link from 'components/Link';

import messages from './messages';

type ErrorStateProps = {
  getAccountError?: TooLazyToType;
  getProfileError?: TooLazyToType;
  javascriptError?: TooLazyToType;
};

const ErrorState = ({
  getAccountError,
  getProfileError,
  javascriptError,
}: ErrorStateProps) => {
  let error: React.ReactNode;

  const { formatMessage } = useIntl();
  const errorHelp = formatMessage(messages.errorHelp, {
    reloading: (
      <Link to={window.location.href} target="_self">
        {formatMessage(messages.errorHelpReloading)}
      </Link>
    ),
    email: (
      <Link to="mailto:support@workpath.com" target="_blank">
        support@workpath.com
      </Link>
    ),
  });

  if (javascriptError || getAccountError || getProfileError) {
    let message: MessageDescriptor;

    if (javascriptError) {
      message = messages.javascriptError;
    } else if (getAccountError) {
      message = messages.accountError;
    } else if (getProfileError) {
      message = messages.profileError;
    }

    error = (
      <>
        <Text fontSize={16} color="navy">
          {formatMessage(message)}
        </Text>

        <Text fontSize={16} color="navy" mt={4}>
          {errorHelp}
        </Text>

        <Box mt={2}>
          <Alert kind="danger">
            {errorMessage(
              javascriptError || getAccountError || getProfileError
            )}
          </Alert>

          <Box mt={2}>
            <Text fontSize={12} color="grey-dark">
              {formatMessage(messages.debugInformation, {
                subdomain: getSubdomain(),
                userAgent: navigator.userAgent,
              })}
            </Text>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <NonAuthenticatedAppLayout hideSecondaryContent>
      <Text fontSize={32} fontWeight="bold" color="navy">
        {formatMessage(messages.heading)}
      </Text>

      <Box mt={6}>{error}</Box>
    </NonAuthenticatedAppLayout>
  );
};

export default ErrorState;
