import { createSelector } from 'reselect';

const selectLocationState = () => {
  let prevRoutingState;

  return state => {
    const routingState = state.route;

    if (JSON.stringify(routingState) !== JSON.stringify(prevRoutingState)) {
      prevRoutingState = routingState;
    }

    return prevRoutingState;
  };
};

// Account
// -------
// app > account: {
//   current: {
//     name: 'Hooli',
//     subdomain: 'hooli',
//     samlLogin: false,
//     samlType: null,
//     samlLabel: null,
//     passwordLogin: true,
//   },
//   getAccountError: null,
// }
const selectAccountDomain = state => state.app.account;

const selectCurrentAccount = createSelector(
  selectAccountDomain,
  substate => substate.current
);

const selectGetAccountError = createSelector(
  selectAccountDomain,
  substate => substate.getAccountError
);

// User
// ----
// app > user: {
//   token: 'TEpdZLp1bcd2eiHeanYcoXMS',
//   current: {
//     id: 1,
//     firstName: 'Bernd ',
//     lastName: 'Miksch',
//     email: 'demo+1@workpath.com',
//     jobTitle: 'CEO',
//     superAdmin: true,
//     loginType: 'password',
//     avatarUrlMedium: 'http://localhost:3000/system/users-avatars-1-medium.jpg',
//     avatarUrlLarge: 'http://localhost:3000/system/users-avatars-1-large.jpg',
//     confirmedAt: '2017-05-09T10:28:30.588Z',
//     locale: 'en',
//   },
//   updateProfilePayload: null,
//   updateProfilePending: false,
//   getProfileError: null,
// }
const selectUserDomain = state => state.app.user;

const selectCurrentUser = createSelector(
  selectUserDomain,
  substate => substate.current
);

const selectUpdateProfilePending = createSelector(
  selectUserDomain,
  substate => substate.updateProfilePending
);

const selectGetProfileError = () =>
  createSelector(selectUserDomain, substate => substate.getProfileError);

const selectIsLead = createSelector(
  selectUserDomain,
  substate => substate.teams.isLead
);

const selectIsMember = createSelector(
  selectUserDomain,
  substate => substate.teams.isMember
);

const selectIsCoach = createSelector(
  selectUserDomain,
  substate => substate.teams.isCoach
);

// Cycles
// ------
// cycles: {
//   cycles: [...],
//   permissions: [...],
//   getCyclesPending: false,
//   selectedCycle: {...},
// }
const selectCyclesDomain = state => state.app.cycles;
const selectCycles = createSelector(
  selectCyclesDomain,
  substate => substate.cycles
);

const selectCyclePermissions = createSelector(
  selectCyclesDomain,
  substate => substate.permissions
);

const selectGetCyclesPending = createSelector(
  selectCyclesDomain,
  substate => substate.getCyclesPending
);

const selectSelectedCycle = createSelector(
  selectCyclesDomain,
  substate => substate.selectedCycle
);

export {
  selectLocationState,
  selectAccountDomain,
  selectCurrentAccount,
  selectGetAccountError,
  selectUserDomain,
  selectCurrentUser,
  selectUpdateProfilePending,
  selectGetProfileError,
  selectCyclesDomain,
  selectCycles,
  selectCyclePermissions,
  selectGetCyclesPending,
  selectSelectedCycle,
  selectIsMember,
  selectIsLead,
  selectIsCoach,
};
