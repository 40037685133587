import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { getColor, getFontSize } from 'styles/themeGetters';
import { Box } from '../../../Box';
import { Tooltip } from '../../../Tooltip';
import { IconBox, Icons } from '../../../Icons';
import messages from './messages';

type ExpanderRendererProps = {
  onClick: () => void;
  isExpanded: boolean;
  rowDepth: number;
  maxDepth: number;
};

const StyledChevronWrapper = styled(Box)`
  font-size: ${getFontSize(10)};
  border-radius: 4px;
  color: ${getColor('navy')};
  &:hover {
    background-color: ${getColor('grey-light-100')};
  }
`;

const DisabledStyledChevronWrapper = styled(Box)`
  font-size: ${getFontSize(10)};
  border-radius: 4px;
  color: ${getColor('navy')};
`;

export const ExpanderRenderer = ({
  onClick,
  isExpanded,
  rowDepth,
  maxDepth,
}: ExpanderRendererProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {rowDepth < maxDepth ? (
        <StyledChevronWrapper
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="24px"
          height="24px"
          onClick={onClick}
          className="rowExpansionToggle"
        >
          {isExpanded ? (
            <IconBox aria-label="collapse row">
              <Icons.ChevronDown color="grey-dark" />
            </IconBox>
          ) : (
            <IconBox aria-label="expand row">
              <Icons.ChevronRight color="grey-dark" />
            </IconBox>
          )}
        </StyledChevronWrapper>
      ) : (
        <Tooltip
          tooltipContent={formatMessage(messages.expanderRendererLimit)}
          placement="top"
          maxWidth="300px"
        >
          <DisabledStyledChevronWrapper
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="24px"
            height="24px"
          >
            <IconBox aria-label="expand row">
              <Icons.ChevronRight color="grey" />
            </IconBox>
          </DisabledStyledChevronWrapper>
        </Tooltip>
      )}
    </>
  );
};
