import { CurrentUser, UserPermissions } from 'types/CurrentUser';
import { UserFeatureFlagName } from 'types/UserFeatureFlags';
import localeFromBrowser from 'containers/App/lib/localeFromBrowser';
import { accessUserFeatureFlags, Awaitable } from './util';
import { useCurrentUser } from './baseState/useCurrentUser';

type AwaitableIsViewer = Awaitable<boolean>;
type AwaitableUserFeatureFlag = Awaitable<boolean>;

type PermissionKey = keyof UserPermissions;

const useCurrentUserAwaitableWithTransform: <TData>(
  transform: (t: CurrentUser) => TData
) => Awaitable<TData> = transform => {
  const currentUser = useCurrentUser();
  if (currentUser) {
    return { loading: false, data: transform(currentUser) };
  }
  return { loading: true };
};

export const useIsCurrentUserViewerAwaitable = (): AwaitableIsViewer =>
  useCurrentUserAwaitableWithTransform(u => u.viewer);

export const useIsCurrentUserAnalystAwaitable = (): AwaitableIsViewer =>
  useCurrentUserAwaitableWithTransform(u => u.analyst);

export const useHasUserFeatureAwaitable = (
  ff: UserFeatureFlagName
): AwaitableUserFeatureFlag =>
  useCurrentUserAwaitableWithTransform(u =>
    accessUserFeatureFlags(u).includes(ff)
  );

export const useIsCurrentUserViewer = (): boolean => {
  const awaitable = useIsCurrentUserViewerAwaitable();
  return awaitable.loading === false && awaitable.data;
};

export const useIsCurrentUserRegularUser = (): boolean => {
  const awaitable = useIsCurrentUserViewerAwaitable();
  return awaitable.loading === false && !awaitable.data;
};

export const useIsCurrentUserAnalyst = () => {
  const awaitable = useIsCurrentUserAnalystAwaitable();
  return awaitable.loading === false && awaitable.data;
};

export const useHasUserFeature = (ff: UserFeatureFlagName) => {
  const awaitable = useHasUserFeatureAwaitable(ff);
  return awaitable.loading === false && awaitable.data;
};

export const useLocaleAwaitable = () =>
  useCurrentUserAwaitableWithTransform(u => u.locale);

export const useLocale = () => {
  const awaitable = useLocaleAwaitable();
  return awaitable.loading === false ? awaitable.data : localeFromBrowser();
};

const useUserPermissionAwaitable = () =>
  useCurrentUserAwaitableWithTransform(u => u.permissions);

export const useUserPermission = <T extends PermissionKey>(
  permissionKey: T
): boolean => {
  const awaitable = useUserPermissionAwaitable();
  const permissions = awaitable.loading === false && awaitable.data;

  if (permissions) {
    return permissions[permissionKey];
  }

  return false;
};
