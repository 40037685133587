import React from 'react';
import { Cell } from 'react-table';
import {
  ColumnData,
  ColumnTypes,
  ColumnKey,
  ColumnConfig,
  Row,
} from '../../types';
import { OwnerRenderer } from '../OwnerRenderer';
import { TeamRenderer } from '../TeamRenderer';
import { ConfidenceLevelRenderer } from '../ConfidenceLevelRenderer';
import { LastUpdateRenderer } from '../LastUpdateRenderer';
import { ProgressIndicatorRenderer } from '../ProgressIndicatorRenderer';
import { InitiativeStatusRenderer } from '../InitiativeStatusRenderer';
import { TitleRenderer } from '../TitleRenderer';
import { ActionsRenderer } from '../ActionsRenderer';
import { TD } from '../../../Table';

type TitleRendererReactTableVersionProps = {
  getToggleRowExpandedProps: () => boolean;
  isExpanded: boolean;
  canExpand: boolean;
};

/**
 * This function is responsible for highlighting rows when they have been updated.
 * - If a key result has been updated, it will highlight both the key result and the parent goal.
 * - If an initiative has been updated, it will only highlight the initiative and skip the parent goal.
 */
export const checkRecentlyUpdatedInRow = (row): boolean => {
  if (row?.original?.data?.lastUpdated?.recentlyUpdated) {
    return true;
  }

  return (
    row.subRows?.some(
      subRow =>
        subRow?.original?.entityType === 'key_result' &&
        subRow?.original?.data?.lastUpdated?.recentlyUpdated
    ) ?? false
  );
};

export const DynamicCellRenderer = <T extends ColumnKey>(
  props: Cell<ColumnTypes[T]['data']> & {
    columnConfig: ColumnConfig;
    columnConfigs: ColumnConfig[];
    loadingState: 'success' | 'loading';
    isDynamicDataGrid?: boolean;
    handleRowClick?: (row: Row) => void;
    address?: number[];
  }
) => {
  const isDynamicDataGrid = props.isDynamicDataGrid ?? false;
  // TODO: Remove props.value once all grids are using DynamicDataGrid (props.value is deprecated in @tanstack/react-table)
  const cell: ColumnData = isDynamicDataGrid
    ? props.cell?.getValue()
    : props.value;

  const { loadingState } = props;

  const titleRendererProps: TitleRendererReactTableVersionProps = isDynamicDataGrid
    ? {
        getToggleRowExpandedProps: props.row.toggleExpanded,
        isExpanded: props.row.getIsExpanded(),
        canExpand: props.row.getCanExpand(),
      }
    : {
        getToggleRowExpandedProps: props.row.getToggleRowExpandedProps,
        isExpanded: props.row.isExpanded,
        canExpand: props.row.canExpand,
      };

  const {
    isExpanded,
    canExpand,
    getToggleRowExpandedProps,
  } = titleRendererProps;

  // TODO this could benefit from better typing.
  //  It works, but typescript can't map the keys to the respective config anymore.
  // construct a dict of all configs keyed by the repsective header type
  const configs = React.useMemo(
    () =>
      props.columnConfigs.reduce(
        (prev, cur) => ({ ...prev, [cur.key]: cur }),
        {}
      ),
    [props.columnConfigs]
  );

  if (!cell) return <TD />;

  switch (cell.entityType) {
    // @ts-ignore
    case 'title_loading_cell':
    case 'goal_title':
    case 'aligned_goal_title':
    case 'key_result_title':
    case 'initiative_title':
    case 'goal_group_title':
      return (
        <TitleRenderer
          cell={cell}
          getToggleRowExpandedProps={getToggleRowExpandedProps}
          isExpanded={isExpanded}
          canExpand={canExpand}
          config={configs.title}
          loadingState={loadingState}
          rowDepth={props.row.depth}
          maxDepth={props.maxDepth}
          isDynamicDataGrid={isDynamicDataGrid}
          handleRowClick={props.handleRowClick}
        />
      );
    // @ts-ignore
    case 'owner_loading_cell':
    case 'owner':
    case 'owner_unassigned':
    case 'owner_team':
      return (
        <OwnerRenderer
          cell={cell}
          config={configs.owner}
          loadingState={loadingState}
        />
      );
    // @ts-ignore
    case 'team_loading_cell':
    case 'team':
      return (
        <TeamRenderer
          cell={cell}
          config={configs.team}
          loadingState={loadingState}
        />
      );
    // @ts-ignore
    case 'progress_loading_cell':
    case 'goal_progress':
    case 'draft_goal_progress':
    case 'key_result_progress':
    case 'draft_key_result_progress':
      return (
        <ProgressIndicatorRenderer
          cell={cell}
          config={configs.progress}
          loadingState={loadingState}
        />
      );
    case 'initiative_progress':
    case 'draft_initiative_progress':
      return (
        <InitiativeStatusRenderer
          cell={cell}
          config={configs.progress}
          loadingState={loadingState}
        />
      );
    // double case because the Renderer works for both cases (right now)
    // @ts-ignore
    case 'confidence_level_loading_cell':
    case 'goal_confidence_level':
    case 'draft_goal_confidence_level':
    case 'key_result_confidence_level':
    case 'draft_key_result_confidence_level':
    case 'initiative_confidence_level':
      return (
        <ConfidenceLevelRenderer
          cell={cell}
          config={configs.confidenceLevel}
          loadingState={loadingState}
        />
      );
    // @ts-ignore
    case 'last_updated_loading_cell':
    case 'goal_last_updated':
    case 'draft_goal_last_updated':
    case 'key_result_last_updated':
    case 'draft_key_result_last_updated':
    case 'initiative_last_updated':
    case 'draft_initiative_last_updated':
      return (
        <LastUpdateRenderer
          cell={cell}
          config={configs.lastUpdated}
          loadingState={loadingState}
          hasRecentlyUpdated={checkRecentlyUpdatedInRow(props.row)}
        />
      );
    // @ts-ignore
    case 'actions_loading_cell':
    case 'goal_actions':
    case 'draft_goal_actions':
    case 'key_result_actions':
    case 'draft_key_result_actions':
    case 'initiative_actions':
    case 'conversation_goal_actions':
    case 'conversation_key_result_actions':
    case 'conversation_initiative_actions':
    case 'conversation_aligned_goal_actions':
      return (
        <ActionsRenderer
          cell={cell}
          config={configs.actions}
          loadingState={loadingState}
          address={props.address}
        />
      );
    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = cell;
      return _exhaustiveCheck;
    }
  }
};
