/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { PropsWithChildren } from 'react';
import { IntlProvider, createIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { useLocale } from 'utils/hooks/globalState';
import { TooLazyToType } from 'types/TooLazyToType';

type I18NMessages = TooLazyToType;

const LanguageProvider = ({
  messages,
  children,
}: PropsWithChildren<{ messages: I18NMessages }>) => {
  const selectedLocale = useLocale();
  const intlMessages = messages[selectedLocale];

  // This is exposed as a service in app/utils/intlService.js
  // @ts-ignore ts isn't happy about this global assign
  window.intlService = createIntl({
    locale: selectedLocale,
    messages: intlMessages,
  });

  return (
    <>
      <Helmet>
        <html lang={selectedLocale} />
      </Helmet>
      <IntlProvider
        locale={selectedLocale}
        key={selectedLocale}
        messages={intlMessages}
      >
        {React.Children.only(children)}
      </IntlProvider>
    </>
  );
};

export default LanguageProvider;
