import {
  CHANGE_LOCALE,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  CREATE_USER_SESSION,
  DESTROY_USER_SESSION,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_ERROR,
  GET_CYCLES_REQUEST,
  GET_CYCLES_SUCCESS,
  GET_CYCLES_ERROR,
  GET_CUSTOM_CYCLE_REQUEST,
  GET_CUSTOM_CYCLE_SUCCESS,
  GET_CUSTOM_CYCLE_ERROR,
  SET_SELECTED_CYCLE,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
} from './constants';

const userInitialState = {
  current: undefined,
  teams: {
    teams: [],
    isCoach: [],
    isMember: [],
    isLead: [],
  },
};

export function accountReducer(state = {}, action) {
  switch (action.type) {
    case GET_ACCOUNT_SUCCESS:
      return { ...state, current: action.response, getAccountError: null };

    case GET_ACCOUNT_ERROR:
      return { ...state, getAccountError: action.error };

    default:
      return state;
  }
}

export function userReducer(state = userInitialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, current: { ...state.current, locale: action.locale } };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileError: null,
        current: action.response,
      };

    case GET_PROFILE_ERROR:
      return { ...state, getProfileError: action.error };

    case UPDATE_PROFILE_REQUEST:
      return { ...state, updateProfilePending: true };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfilePending: false,
        current: action.response,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfilePending: false,
      };

    case CREATE_USER_SESSION:
      return {
        ...state,
        current: action.user,
      };
    case DESTROY_USER_SESSION:
      return {
        ...state,
        current: null,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        logoutError: null,
      };
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        logoutError: action.error,
      };
    case GET_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload,
      };
    }
    case GET_TEAMS_ERROR:
      return {
        ...state,
        getTeamsError: action.payload,
      };
    default:
      return state;
  }
}

export function cyclesReducer(state = {}, action) {
  switch (action.type) {
    case GET_CYCLES_REQUEST:
      return {
        ...state,
        getCyclesError: null,
        getCyclesPending: true,
      };
    case GET_CYCLES_SUCCESS:
      return {
        ...state,
        getCyclesPending: false,
        cycles: action.response.cycles,
        permissions: action.response.permissions,
        selectedCycle: action.response.cycles.find(cycle => cycle.current),
      };
    case GET_CYCLES_ERROR:
      return { ...state, getCyclesPending: false, error: action.error };

    case GET_CUSTOM_CYCLE_REQUEST:
      return { ...state, getCyclesError: null, getCyclesPending: true };
    case GET_CUSTOM_CYCLE_SUCCESS:
      return {
        ...state,
        getCyclesPending: false,
        cycles: action.response.cycles,
        permissions: action.response.permissions,
        selectedCycle: action.response.cycles.find(
          cycle => cycle.id === action.customCycleId
        ),
      };
    case GET_CUSTOM_CYCLE_ERROR:
      return { ...state, getCyclesPending: false, error: action.error };
    case SET_SELECTED_CYCLE:
      return { ...state, selectedCycle: action.cycle };

    default:
      return state;
  }
}
