import React, { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { HeaderProps } from 'react-table';

import { Box } from '../../../Box';
import { Text } from '../../../Text';
import { Tooltip } from '../../../Tooltip';

import { Row, ColumnKey } from '../../types';

import messages from './messages';
import { Truncate } from '../../../Truncate';
import { TH } from '../../../Table';

type DynamicHeaderRendererProps = HeaderProps<Row>;

const textProps: Partial<ComponentProps<typeof Text>> = {
  color: 'black',
  fontSize: 14,
  fontWeight: 'semibold',
};
const StyledText = styled(Text).attrs(textProps)`
  flex-grow: 1;
`;

// TODO: find a nicer solution to fix all col widths and apply that to truncation values
export const DynamicHeaderRenderer = (props: DynamicHeaderRendererProps) => {
  const { formatMessage } = useIntl();

  const colKey: ColumnKey = props.column.id as ColumnKey;

  switch (colKey) {
    case 'title':
      return (
        <TH>
          <Box display="flex" alignItems="center" pl={2} pr={2}>
            <StyledText ml={2}>{formatMessage(messages[colKey])}</StyledText>
          </Box>
        </TH>
      );

    case 'owner':
    case 'team':
      return (
        <TH pl="0">
          <Truncate
            textProps={{ ...textProps, textAlign: 'center' }}
            maxWidth="60px"
            text={formatMessage(messages[colKey])}
          />
        </TH>
      );

    case 'progress':
    case 'lastUpdated':
    case 'actions':
      return (
        <TH>
          <StyledText>{formatMessage(messages[colKey])}</StyledText>
        </TH>
      );

    case 'confidenceLevel':
      return (
        <TH pl="0">
          <Tooltip
            tooltipContent={formatMessage(messages.confidenceLevelTooltip)}
          >
            <StyledText textAlign="center">
              {formatMessage(messages[colKey])}
            </StyledText>
          </Tooltip>
        </TH>
      );

    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = colKey;
      return _exhaustiveCheck;
    }
  }
};
