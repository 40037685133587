import React from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';

import { Box, Text } from 'baukasten';
import { withProps } from 'utils/hocs';
import { useLocale } from 'utils/hooks/globalState';
import { PublicAccount } from 'types/Account';

import Link from 'components/Link';
import TruncatedLink from 'components/DescriptionText/TruncatedLink';

import { getCustomHelpText } from '../utils';

import AccountNotifications from './AccountNotifications';

const CustomerLogo = styled.img`
  max-width: 100%;
`;

type PrimaryContentProps = {
  children: React.ReactNode;
  publicAccountData: PublicAccount;
};

export const PrimaryContent = ({
  children,
  publicAccountData,
}: PrimaryContentProps) => {
  const locale = useLocale();

  const customHelpText = getCustomHelpText(publicAccountData, locale);
  const customHelpTextLinkComponent = withProps({ linkComponent: Link })(
    TruncatedLink
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ flexGrow: 1 }}
    >
      <Box width="400px">
        {publicAccountData && (
          <AccountNotifications account={publicAccountData} />
        )}

        {publicAccountData?.logoUrl && (
          <Box mb={8}>
            <CustomerLogo
              src={publicAccountData.logoUrl}
              alt="customer logo"
              height={32}
            />
          </Box>
        )}

        {children}

        {customHelpText && (
          <Linkify
            component={customHelpTextLinkComponent}
            properties={{ target: '_blank' }}
          >
            <Text fontSize={14} color="navy" mt={4}>
              {customHelpText}
            </Text>
          </Linkify>
        )}
      </Box>
    </Box>
  );
};
