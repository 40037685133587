import { getRequestV2, postRequestV2 } from '../methods';

const createStrategicElement = payload =>
  postRequestV2('strategic_elements', payload);

const getStrategicElements = (ownerType: string, ownerId?: string) =>
  getRequestV2(
    `strategic_elements?owner_type=${ownerType}&owner_id=${ownerId}`
  );

const strategicElements = {
  createStrategicElement,
  getStrategicElements,
};

export default strategicElements;
